import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const Terms = () => (
  <Container>
    <Row>
      <Col>
        Coming soon...
      </Col>
    </Row>
  </Container>
);

export default Terms;

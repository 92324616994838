import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Container, Col, Row, Table, Button, Image,
} from 'react-bootstrap';

import PlayersImage from '../assets/images/players-side.png';
import GuildsImage from '../assets/images/guilds-size.png';
import JinnoImage from '../assets/images/Jinno_2.png';
import ChunjoImage from '../assets/images/Chunjo_2.png';
import ShinsoImage from '../assets/images/Shinsho_2.png';
import './RankingSide.css';
import { get } from '../adapters/xhr';

const RankingSide = () => {
  const { t } = useTranslation();

  const [data, setData] = useState([]);
  const [showRanking, setShowRanking] = useState('players');

  const getRanking = async () => {
    let result = [];
    if (showRanking === 'players') {
      result = await get('/assets/cache/players.json');
    } else {
      result = await get('/assets/cache/guilds.json');
    }
    setData(result.data.slice(0, 10));
  };

  useEffect(async () => { await getRanking(); }, [showRanking]);

  return (
    <Container>
      <Row className="pt-3 text-center">
        <Col>
          <span className="text-capitalize">{t('ranking')}</span>
        </Col>
      </Row>
      <hr className="hr-color"/>
      <Row className="mb-4">
        <Col className="align-self-center text-center hover-shadow" onClick={() => { setShowRanking('players'); }}>
          <Image src={PlayersImage} alt="" />
        </Col>
        <Col className="align-self-center text-center hover-shadow" onClick={() => { setShowRanking('guilds'); }}>
          <Image src={GuildsImage} alt="" />
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <Table size="sm" borderless striped>
            <thead className="text-primary-gold-custom">
              <th className="font-weight-light">#</th>
              <th className="font-weight-light text-left">{t('name')}</th>
              <th className="font-weight-light">{t('empire')}</th>
              <th className="font-weight-light">{t('level')}</th>
            </thead>
            <tbody>
            {data.map((entry, index) => (
              <tr key={entry.id}>
                <td className="text-secondary-custom font-weight-light">{index + 1}.</td>
                <td className="text-primary-gold-custom text-left font-weight-light">{entry.name}</td>
                <td>
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {entry.empire === 3 ? <img src={JinnoImage} alt="" />
                    : entry.empire === 2 ? <img src={ChunjoImage} alt="" />
                      : <img src={ShinsoImage} alt="" />
                  }
                </td>
                <td className="text-secondary-custom font-weight-light">{entry.level}</td>
              </tr>))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col className="text-center">
          <Button size="sm" as={Link} to="/ranking" className="form-submit-button">
            {t('view-more')}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default RankingSide;

import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthProvider from '../contexts/AuthProvider';

const AuthenticatedRoute = ({ component: C, appProps, ...rest }) => {
  const { account } = useContext(AuthProvider.context);
  const { auth, user } = account;

  return (
    <Route
      {...rest}
      render={(props) => (auth && user.admin > 0
        ? <C {...props} {...appProps} />
        : <Redirect
          to={'/'}
        />)}
    />
  );
};

export default AuthenticatedRoute;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import {
  Alert, Button, Col, Container, Form, Row,
} from 'react-bootstrap';
import { post } from '../adapters/xhr';

const UserResetSocialid = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const performSocialIdRecovery = async () => {
    try {
      await post('/api/user/account/recovery/socialid');

      addToast(t('reset-process-success'), { appearance: 'success' });
    } catch (e) {
      if (e.response.status === 400) {
        addToast(t('reset-process-delay-1h'), { appearance: 'warning' });
      } else {
        addToast(t('process-error'), { appearance: 'error' });
      }
    }
  };

  return (
    <Container className="mt-4">
      <Row>
        <Col>
          <Alert variant="info">
            {t('email-after-submit')}
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <Form className="py-2 text-center">
            <Button size="sm" className="form-submit-button" onClick={async () => {
              await performSocialIdRecovery();
            }}>
              {t('submit')}
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default UserResetSocialid;

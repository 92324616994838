import React, { useState } from 'react';
import {
  Container, Row, Col, Nav,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

import AdminGlobalSettings from './AdminGlobalSettings';
import AdminNewsSettings from './AdminNewsSettings';
import AdminDownloadSettings from './AdminDownloadsSettings';
import AdminAccountSettings from './AdminAccountSettings';
import AdminPlayerSettings from './AdminPlayerSettings';
import AdminGuildSettings from './AdminGuildSettings';
import AdminAccountBanlist from './AdminAccountBanlist';

const AdminProfile = () => {
  const { t } = useTranslation();

  const [selectedNav, setSelectedNav] = useState('web');
  const [selectedSettingsPage, setSelectedSettingsPage] = useState(null);

  const renderSelectedNav = () => {
    switch (selectedNav) {
      case 'web': return (
        <Nav>
          <Nav.Item className="choose-settings-navbar-item">
            <Nav.Link eventKey="global" onClick={() => { setSelectedSettingsPage('web-global'); }}>{t('global-settings')}</Nav.Link>
          </Nav.Item>
          <Nav.Item className="choose-settings-navbar-item">
            <Nav.Link eventKey="news" onClick={() => { setSelectedSettingsPage('web-news'); }}>{t('announcements')}</Nav.Link>
          </Nav.Item>
          <Nav.Item className="choose-settings-navbar-item">
            <Nav.Link eventKey="download" onClick={() => { setSelectedSettingsPage('web-downloads'); }}>{t('download-links')}</Nav.Link>
          </Nav.Item>
        </Nav>
      );
      case 'user': return (<Nav>
        <Nav.Item className="choose-settings-navbar-item">
          <Nav.Link eventKey="accounts" onClick={() => { setSelectedSettingsPage('web-accounts'); }}>{t('accounts')}</Nav.Link>
        </Nav.Item>
        <Nav.Item className="choose-settings-navbar-item">
          <Nav.Link eventKey="players" onClick={() => { setSelectedSettingsPage('web-players'); }}>{t('players')}</Nav.Link>
        </Nav.Item>
        <Nav.Item className="choose-settings-navbar-item">
          <Nav.Link eventKey="guilds" onClick={() => { setSelectedSettingsPage('web-guilds'); }}>{t('guilds')}</Nav.Link>
        </Nav.Item>
        <Nav.Item className="choose-settings-navbar-item">
          <Nav.Link eventKey="banlist" onClick={() => { setSelectedSettingsPage('web-banlist'); }}>{t('banlist')}</Nav.Link>
        </Nav.Item>
      </Nav>);
      default: return <div />;
    }
  };

  const renderSelectedSettingsPage = () => {
    switch (selectedSettingsPage) {
      case 'web-global': return <AdminGlobalSettings />;
      case 'web-news': return <AdminNewsSettings />;
      case 'web-downloads': return <AdminDownloadSettings />;
      case 'web-accounts': return <AdminAccountSettings />;
      case 'web-players': return <AdminPlayerSettings />;
      case 'web-guilds': return <AdminGuildSettings />;
      case 'web-banlist': return <AdminAccountBanlist />;
      default: return <div />;
    }
  };

  return (
    <Container className="py-3">
      <Row>
        <Col className="text-center">
          <h5 className="text-uppercase"><FontAwesomeIcon icon={faUser}/> {t('admin-panel')}</h5>
        </Col>
      </Row>
      <hr className="hr-color" />
      <Row className="mb-3">
        <Col>
          <Nav fill defaultActiveKey="web">
            <Nav.Item className="choose-settings-navbar-item">
              <Nav.Link eventKey="web" onClick={() => { setSelectedNav('web'); }}>{t('website-settings')}</Nav.Link>
            </Nav.Item>
            <Nav.Item className="choose-settings-navbar-item">
              <Nav.Link eventKey="user" onClick={() => { setSelectedNav('user'); }}>{t('user-settings')}</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          { renderSelectedNav() }
        </Col>
      </Row>
          { renderSelectedSettingsPage() }
    </Container>
  );
};

export default AdminProfile;

import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import {
  Container, Row, Col, Image,
} from 'react-bootstrap';

import InteractiveButtons from './InteractiveButtons';
import Slider from './Carousel';
import UserPanel from './UserPanel';
import RankingSide from './RankingSide';
import News from './News';
import Register from './Register';
import Ranking from './Ranking';
import Download from './Download';
import Terms from './Terms';
import AdminProfile from './AdminProfile';
import UserProfile from './UserProfile';
import AuthenticatedRoute from './AuthentificatedRoute';
import NotAuthenticatedRoute from './NotAuthenticatedRoute';
import AdminRoute from './AdminRoute';
import ResetPassword from './ResetPassword';
import ResetPasswordConfirm from './ResetPasswordConfirm';
import ResetUsername from './ResetUsername';
import UserChangeEmailOldConfirm from './UserChangeEmailOldConfirm';
import UserChangeEmailNewConfirm from './UserChangeEmailNewConfirm';
import RegisterConfirm from './RegisterConfirm';
import Login from './Login';
import NotFound from './NotFound';
import './Main.css';
import Logo from '../assets/images/logo.png';
import RegisterImage from '../assets/images/register.png';

const Main = () => (
  <Container className="header-padding">
    <Row className="pt-5">
      <Col>
        <Image src={Logo} className="header-logo" alt="Logo" />
      </Col>
    </Row>
    <Row>
      <Col>
        <Link to="/register">
          <Image src={RegisterImage} alt="Register now" />
        </Link>
      </Col>
    </Row>
    <Row noGutters="true">
      <Col lg={3} className="interactive-panel">
        <InteractiveButtons />
      </Col>
      <Col lg={6}>
        <Slider />
      </Col>
      <Col lg={3}>
        <UserPanel />
      </Col>
    </Row>
    <Row noGutters="true" className="section-bg d-flex align-items-start">
      <Col lg={9} className="main-panel">
        <Switch>
          <Route exact path="/" component={News} />
          <NotAuthenticatedRoute exact path="/register" component={Register} />
          <NotAuthenticatedRoute exact path="/register/confirm/:username/:uniqueKey" component={RegisterConfirm} />
          <Route exact path="/ranking" component={Ranking} />
          <Route exact path="/download" component={Download} />
          <Route exact path="/terms" component={Terms} />
          <NotAuthenticatedRoute path="/login" component={Login} />
          <AuthenticatedRoute exact path="/user/profile" component={UserProfile} />
          <Route exact path="/user/email/validate/:username/:uniqueKey" component={UserChangeEmailOldConfirm} />
          <Route exact path="/user/email/accept/:username/:uniqueKey" component={UserChangeEmailNewConfirm} />
          <AdminRoute exact path="/admin/profile" component={AdminProfile} />
          <NotAuthenticatedRoute exact path="/recovery/password" component={ResetPassword} />
          <NotAuthenticatedRoute exact path="/recovery/password/confirm/:username/:uniqueKey" component={ResetPasswordConfirm} />
          <NotAuthenticatedRoute exact path="/recovery/username" component={ResetUsername} />
          <Route component={NotFound} />
        </Switch>
      </Col>
      <Col lg={3} className="ranking-panel">
        <div>
          <RankingSide />
        </div>
      </Col>
    </Row>
  </Container>
);

export default Main;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import {
  Button, Col, Container, Form, FormControl, InputGroup, Row,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { post } from '../adapters/xhr';

const ResetPassword = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');

  const performPasswordRecovery = async () => {
    try {
      await post('/api/user/account/recovery/password', {
        username,
        email,
      });

      addToast(t('reset-process-success'), { appearance: 'success' });
    } catch (e) {
      if (e.response.status === 400) {
        addToast(t('reset-process-delay-24h'), { appearance: 'warning' });
      } else {
        addToast(t('process-error'), { appearance: 'error' });
      }
    }
  };

  return (
    <Container>
      <Row className="pt-3 text-center">
        <Col>
          <h5 className="text-uppercase"><FontAwesomeIcon icon={faUserPlus}/> {t('reset-password')}</h5>
        </Col>
      </Row>
      <hr className="hr-color"/>
      <Row>
        <Col className="d-flex justify-content-center">
          <Form className="py-2 text-center">
            <InputGroup size="sm" className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text style={{ color: 'white' }}
                                 className="form-control-custom">{t('user-name')}</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                className="form-control-custom"
                onChange={(e) => { setUsername(e.target.value); }}
              />
            </InputGroup>

            <InputGroup size="sm" className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon2"
                                 className="form-control-custom">{t('email-address')}</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                type="email"
                className="form-control-custom"
                onChange={(e) => { setEmail(e.target.value); }}
              />
            </InputGroup>

            <Button size="sm" className="form-submit-button" onClick={async () => {
              await performPasswordRecovery();
            }}>
              {t('submit')}
            </Button>
          </Form>
        </Col>
      </Row>
      <Row className="text-center my-3">
        <Col>
          <small><FontAwesomeIcon icon={faArrowCircleLeft}/> <Link to="/login">{t('back-to-login')}</Link></small>
        </Col>
      </Row>
    </Container>
  );
};

export default ResetPassword;

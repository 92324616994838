import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

const context = createContext(null);

const AuthProvider = ({ children }) => {
  const [account, setAccount] = useState({ auth: false, user: null });

  useEffect(async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_API_SERVER_URL}/api/auth`);
    setAccount(data);
  }, []);

  return (
    <context.Provider value={{ account, setAccount }}>
      { children }
    </context.Provider>
  );
};

AuthProvider.context = context;

export default AuthProvider;

import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import io from 'socket.io-client';
import { Image } from 'react-bootstrap';

import DownloadIcon from '../assets/images/download/download-icon.png';
import './InteractiveButtons.css';

const InteractiveButtons = () => {
  const { t } = useTranslation();

  const [playersOnlineNow, setPlayersOnlineNow] = useState(0);
  const [playersOnlineDay, setPlayersOnlineDay] = useState(0);
  const playersOnlineNowRef = useRef();
  const playersOnlineDayRef = useRef();
  playersOnlineNowRef.current = playersOnlineNow;
  playersOnlineDayRef.current = playersOnlineDay;

  const [animationLikesNow, setAnimationLikesNow] = useState('initial');
  const [animationLikesDay, setAnimationLikesDay] = useState('initial');
  const [socket, setSocket] = useState(null);

  const playersCountUpdate = (data) => {
    if (data.now !== playersOnlineNowRef.current) {
      // 1. Old number goes up
      setTimeout(() => setAnimationLikesNow('goUp'), 0);
      // 2. Incrementing the counter
      setTimeout(() => setPlayersOnlineNow(data.now), 100);
      // 3. New number waits down
      setTimeout(() => setAnimationLikesNow('waitDown'), 100);
      // 4. New number stays in the middle
      setTimeout(() => setAnimationLikesNow('initial'), 200);
    }

    if (data.day !== playersOnlineDayRef.current) {
      // 1. Old number goes up
      setTimeout(() => setAnimationLikesDay('goUp'), 0);
      // 2. Incrementing the counter
      setTimeout(() => setPlayersOnlineDay(data.day), 100);
      // 3. New number waits down
      setTimeout(() => setAnimationLikesDay('waitDown'), 100);
      // 4. New number stays in the middle
      setTimeout(() => setAnimationLikesDay('initial'), 200);
    }
  };

  useEffect(() => {
    const newSocket = io(process.env.REACT_APP_API_SERVER_URL);
    setSocket(newSocket);
    return () => socket.disconnect();
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on('players-statistics', playersCountUpdate);
    }
  }, [socket]);

  return (
    <>
      <Link to="/download" className="h-50 d-flex flex-column justify-content-center text-center download-section">
        <div>
          <Image className="download-image" src={DownloadIcon} alt=""/>
        </div>
        <div>
          <h4 className="text-uppercase">{t('download')}</h4>
          <small className="text-uppercase">{t('game-client')}</small>
        </div>
      </Link>
      <div className="h-50 d-flex justify-content-around">
        <span className="align-self-center text-center">
          <div className="h4 player-animation">{
            socket ? (
              <span className={animationLikesNow}>{playersOnlineNow}</span>
            )
              : 'Network problem'
          }</div>
          <small className="text-secondary-custom">{t('players-online')}</small>
        </span>
        <span className="align-self-center text-center">
          <div className="h4 player-animation">{
            socket ? (
                <span className={animationLikesDay}>{playersOnlineDay}</span>
            )
              : 'Network problem'
          }</div>
          <small className="text-secondary-custom">{t('players-online-24h')}</small>
        </span>
      </div>
    </>
  );
};

export default InteractiveButtons;

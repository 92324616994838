import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {
  Col, Container, Row, Form, Button, Alert,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { Formik } from 'formik';
import { post } from '../adapters/xhr';

const initialUser = {
  username: '',
  password: '',
  passwordVerify: '',
  email: '',
  realName: '',
  socialId: '',
  acceptTerms: false,
};

const Register = () => {
  const { t } = useTranslation();

  const [registerStatus, setRegisterStatus] = useState(true);

  const { executeRecaptcha } = useGoogleReCaptcha();
  const [error, setError] = useState('');

  const performRegister = async (values, token) => {
    const {
      username, email, realName, socialId, password,
    } = values;

    await post('/api/auth/signup', {
      username,
      password,
      email,
      real_name: realName,
      social_id: socialId,
      token,
    });
  };

  const initRegister = async (values) => {
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha('login');
    await performRegister(values, token);
  };

  useEffect(async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_API_SERVER_URL}/api/global/register`);
    setRegisterStatus(data);
  }, []);

  const validationSchema = yup.object().shape({
    username: yup.string().required(t('field-required')),
    password: yup
      .string()
      .min(8, t('password-min'))
      .max(16, t('password-max'))
      .required(t('field-required')),
    passwordVerify: yup
      .string()
      .oneOf(
        [yup.ref('password'), null],
        t('passwords-not-match'),
      )
      .required(t('field-required')),
    email: yup
      .string()
      .email(t('email-format'))
      .required(t('field-required')),
    realName: yup
      .string()
      .matches(
        /^[A-Za-z ]+$/,
        t('name-format'),
      )
      .min(3)
      .max(30)
      .required(t('field-required')),
    socialId: yup
      .string()
      .matches(
        /^[0-9]{7}$/,
        t('social-id-format'),
      )
      .required(t('field-required')),
    acceptTerms: yup.boolean().oneOf([true], t('must-accept-terms')),
  });

  if (!registerStatus) {
    return (
      <Container>
        <Row className="pt-3 text-center">
          <Col>
            <h5 className="text-uppercase"><FontAwesomeIcon icon={faUserPlus}/> {t('register')}</h5>
            <hr className="hr-orange"/>
            <Alert variant="danger">
              <Alert.Heading>{t('hey_welcome')}</Alert.Heading>
              <p>
                {t('register-disabled')}
              </p>
              <hr />
              <p className="mb-0">
                {t('have_great_time')}
              </p>
              <blockquote className="blockquote">
                <footer className="blockquote-footer text-right">{t('king-regards')} <cite title="Source Title">{t('your-team')}</cite>
                </footer>
              </blockquote>
            </Alert>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      <Row className="pt-3 text-center">
        <Col>
          <h5 className="text-uppercase"><FontAwesomeIcon icon={faUserPlus}/> {t('register')}</h5>
        </Col>
      </Row>
      <hr className="hr-color"/>
      {error !== ''
      && <Row className="pt-3 text-center">
        <Col>
          <Alert variant="danger">
            <Alert.Heading>{t('register-error')}</Alert.Heading>
            <p>
              {t(error)}
            </p>
            <hr />
            <blockquote className="blockquote">
              <footer className="blockquote-footer text-right">{t('king-regards')} <cite title="Source Title">{t('your-team')}</cite>
              </footer>
            </blockquote>
          </Alert>
        </Col>
      </Row>}
      <Row>
        <Col>
          <Formik
            initialValues={initialUser}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              await toast.promise(
                initRegister(values),
                {
                  loading: t('loading'),
                  success: t('register-success'),
                  error: (err) => { if (err.response.data.message) setError(err.response.data.message); return t('register-error'); },
                },
              );

              resetForm();
              setSubmitting(false);
            }}
          >
            {({
              touched,
              errors,
              isSubmitting,
              handleSubmit,
              handleChange,
            }) => (
              <Form className="pb-3" onSubmit={handleSubmit}>
                <Form.Row>
                  <Form.Group as={Col} controlId="username">
                    <Form.Label size="sm">{t('user-name')}</Form.Label>
                    <Form.Control
                      className={touched.username && errors.username ? 'form-control-custom error' : 'form-control-custom'}
                      size="sm"
                      placeholder={t('user-name')}
                      onChange={handleChange}
                    />
                    {(touched.username && errors.username) && <div className="error-message">{errors.username}</div>}
                  </Form.Group>

                  <Form.Group as={Col} controlId="realName">
                    <Form.Label>{t('real-name')}</Form.Label>
                    <Form.Control
                      className={touched.realName && errors.realName ? 'form-control-custom error' : 'form-control-custom'}
                      size="sm"
                      placeholder={t('real-name')}
                      onChange={handleChange}
                    />
                    {(touched.realName && errors.realName) && <div className="error-message">{errors.realName}</div>}
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} controlId="password">
                    <Form.Label size="sm">{t('password')}</Form.Label>
                    <Form.Control
                      className={touched.password && errors.password ? 'form-control-custom error' : 'form-control-custom'}
                      size="sm"
                      type="password"
                      placeholder={t('password')}
                      onChange={handleChange}
                    />
                    {(touched.password && errors.password) && <div className="error-message">{errors.password}</div>}
                  </Form.Group>

                  <Form.Group as={Col} controlId="passwordVerify">
                    <Form.Label>{t('password-verify')}</Form.Label>
                    <Form.Control
                      className={touched.passwordVerify && errors.passwordVerify ? 'form-control-custom error' : 'form-control-custom'}
                      size="sm" type="password"
                      placeholder={t('password-verify')}
                      onChange={handleChange}
                    />
                    {(touched.passwordVerify && errors.passwordVerify) && <div className="error-message">{errors.passwordVerify}</div>}
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} controlId="email">
                    <Form.Label>{t('email-address')}</Form.Label>
                    <Form.Control
                      className={touched.email && errors.email ? 'form-control-custom error' : 'form-control-custom'}
                      size="sm"
                      type="email"
                      placeholder={t('email-address')}
                      onChange={handleChange}
                    />
                    {(touched.email && errors.email) && <div className="error-message">{errors.email}</div>}
                  </Form.Group>

                  <Form.Group as={Col} controlId="socialId">
                    <Form.Label>{t('character-deletion')}</Form.Label>
                    <Form.Control
                      className={touched.socialId && errors.socialId ? 'form-control-custom error' : 'form-control-custom'}
                      size="sm"
                      placeholder={t('character-deletion')}
                      onChange={handleChange}
                    />
                    {(touched.socialId && errors.socialId) && <div className="error-message">{errors.socialId}</div>}
                  </Form.Group>
                </Form.Row>

                <div className="text-center">
                  <Form.Group controlId="acceptTerms">
                    <Form.Check
                      size="sm"
                      type="checkbox"
                      label={t('agree-terms')}
                      onChange={handleChange}
                    />
                    {(touched.acceptTerms && errors.acceptTerms) && <div className="error-message">{errors.acceptTerms}</div>}
                  </Form.Group>

                  <Button type="submit" size="sm" className="form-submit-button" disabled={isSubmitting}>
                    {t('register')}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
      <Row className="text-center my-3">
        <Col>
          <small><FontAwesomeIcon icon={faArrowCircleRight}/> <Link to="/login">{t('go-to-login')}</Link></small>
        </Col>
      </Row>
    </Container>
  );
};

export default Register;

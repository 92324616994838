import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Image } from 'react-bootstrap';

import AuthProvider from '../contexts/AuthProvider';
import Logo from '../assets/images/logo.png';
import './Header.scss';

const Header = () => {
  const { t } = useTranslation();
  const { account } = useContext(AuthProvider.context);
  const [navClass, setNavClass] = useState('custom-navbar');

  const listenScrollEvent = () => {
    if (window.scrollY < 100) {
      return setNavClass('custom-navbar');
    }
    return setNavClass('scrolled-navbar');
  };

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);

    return () => window.removeEventListener('scroll', listenScrollEvent);
  }, []);

  if (account.auth) {
    return (
      <Navbar fixed="top" variant="dark" className={navClass} expand="lg">
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="d-flex justify-content-between w-100" justify="true">
            <Nav.Link as={Link} to="/">
              <div className="nav-primary">{t('news')}</div>
              <div className="nav-secondary">{t('news-secondary')}</div>
            </Nav.Link>
            <Nav.Link as={Link} to="/user/profile">
              <div className="nav-primary">{t('user-panel')}</div>
              <div className="nav-secondary">{t('user-panel-secondary')}</div>
            </Nav.Link>
            <Nav.Link as={Link} to="/download">
              <div className="nav-primary">{t('download')}</div>
              <div className="nav-secondary">{t('game-client')}</div>
            </Nav.Link>
            <Nav.Link as={Link} to="/">
              <Image src={Logo} className="nav-logo" alt="Logo" />
            </Nav.Link>
            <Nav.Link as={Link} to="/ranking">
              <div className="nav-primary">{t('ranking')}</div>
              <div className="nav-secondary">{t('ranking-secondary')}</div>
            </Nav.Link>
            <Nav.Link href={process.env.REACT_APP_FORUM_URL} target="_blank">
              <div className="nav-primary">{t('forum')}</div>
              <div className="nav-secondary">{t('forum-secondary')}</div>
            </Nav.Link>
            <Nav.Link href={process.env.REACT_APP_DISCORD_URL} target="_blank">
              <div className="nav-primary">{t('discord')}</div>
              <div className="nav-secondary">{t('discord-secondary')}</div>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }

  return (
    <Navbar fixed="top" variant="dark" className={navClass} expand="lg">
      <Navbar.Toggle aria-controls="basic-navbar-nav"/>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="d-flex justify-content-between w-100" justify="true">
          <Nav.Link as={Link} to="/">
            <div className="nav-primary">{t('news')}</div>
            <div className="nav-secondary">{t('news-secondary')}</div>
          </Nav.Link>
          <Nav.Link as={Link} to="/register">
            <div className="nav-primary">{t('register')}</div>
            <div className="nav-secondary">{t('register-secondary')}</div>
          </Nav.Link>
          <Nav.Link as={Link} to="/download">
            <div className="nav-primary">{t('download')}</div>
            <div className="nav-secondary">{t('game-client')}</div>
          </Nav.Link>
          <Nav.Link as={Link} to="/">
            <Image src={Logo} className="nav-logo" alt="Logo" />
          </Nav.Link>
          <Nav.Link as={Link} to="/ranking">
            <div className="nav-primary">{t('ranking')}</div>
            <div className="nav-secondary">{t('ranking-secondary')}</div>
          </Nav.Link>
          <Nav.Link href={process.env.REACT_APP_FORUM_URL} target="_blank">
            <div className="nav-primary">{t('forum')}</div>
            <div className="nav-secondary">{t('forum-secondary')}</div>
          </Nav.Link>
          <Nav.Link href={process.env.REACT_APP_DISCORD_URL} target="_blank">
            <div className="nav-primary">{t('discord')}</div>
            <div className="nav-secondary">{t('discord-secondary')}</div>
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;

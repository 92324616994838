import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import {
  Row, Col, Button, ListGroup,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import { deleteReq, get, patch } from '../adapters/xhr';

const AdminAccountSettings = () => {
  const { t } = useTranslation();

  const { addToast } = useToasts();

  const [accounts, setAccounts] = useState([]);
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [newName, setNewName] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [searchName, setSearchName] = useState('');
  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleSearch = () => {
    setFilteredAccounts(accounts
      .filter((item) => item.login.toLowerCase().includes(searchName.toLowerCase())));
  };

  const getAccounts = async () => {
    const { data } = await get('/api/admin/accounts');

    setAccounts(data);
    setFilteredAccounts(data.filter((item) => item.login.toLowerCase()
      .includes(searchName.toLowerCase())));
  };

  useEffect(async () => { await getAccounts(); }, []);
  useEffect(() => { handleSearch(); }, [searchName]);

  const updateAccountName = async (id) => {
    try {
      await patch('/api/admin/accounts/name', {
        id,
        name: newName,
      });

      addToast(t('update-account-name-success'), { appearance: 'success' });
      await getAccounts();
    } catch (e) {
      addToast(t('update-account-name-error'), { appearance: 'error' });
    }
  };

  const updateAccountEmail = async (username) => {
    try {
      await patch('/api/admin/accounts/email', {
        username,
        email: newEmail,
      });

      addToast(t('update-account-email-success'), { appearance: 'success' });
      await getAccounts();
    } catch (e) {
      addToast(t('update-account-email-error'), { appearance: 'error' });
    }
  };

  const changeStatusAccount = async (username, status) => {
    try {
      if (status === 'OK') {
        await patch('/api/admin/accounts/block', {
          username,
        });
      } else {
        await patch('/api/admin/accounts/unblock', {
          username,
        });
      }

      addToast(t('account-status-success'), { appearance: 'success' });
      await getAccounts();
    } catch (e) {
      addToast(t('account-status-error'), { appearance: 'error' });
    }
  };
  const showDeleteButton = async () => {
    setConfirmDelete(true);
  };
  const deleteAccount = async (username) => {
    try {
      await deleteReq('/api/admin/accounts', {
        data: {
          username,
        },
      });
      setConfirmDelete(false);
      addToast(t('delete-account-success'), { appearance: 'success' });
      await getAccounts();
    } catch (error) {
      addToast(t('delete-account-error'), { appearance: 'error' });
    }
  };

  const columns = [
    { dataField: 'id', text: 'ID' },
    { dataField: 'login', text: t('user-name') },
    { dataField: 'real_name', text: t('real-name') },
    { dataField: 'email', text: t('email-address') },
    { dataField: 'status', text: t('status') },
  ];

  const expandRow = {
    // eslint-disable-next-line react/display-name
    renderer: (row) => (
      <ListGroup className="text-left">
        <ListGroup.Item className="d-flex justify-content-between dark-input-group">
          <div className="w-100">
            <h5 className="mb-1">{t('change-account-name')}</h5>
            <p className="mb-1">{t('action-instant-effect')}</p>
          </div>
          <div className="input-group align-self-center w-50">
            <input type="text" className="form-control form-control-custom" placeholder={t('new-account-name')}
                   onChange={(e) => { setNewName(e.target.value); }}/>
            <Button size="sm" className="form-submit-button" onClick={ async () => { await updateAccountName(row.id); }}>{t('submit')}</Button>
          </div>
        </ListGroup.Item>

        <ListGroup.Item className="d-flex justify-content-between dark-input-group">
          <div className="w-100">
            <h5 className="mb-1">{t('change-email')}</h5>
            <p className="mb-1">{t('action-instant-effect')}</p>
          </div>
          <div className="input-group align-self-center w-50">
            <input type="text" className="form-control form-control-custom" placeholder={t('new-email')}
                   onChange={(e) => { setNewEmail(e.target.value); }}/>
            <Button size="sm" className="form-submit-button" onClick={ async () => { await updateAccountEmail(row.login); }}>{t('submit')}</Button>
          </div>
        </ListGroup.Item>

        {
          row.status === 'OK'
            ? <ListGroup.Item className="d-flex justify-content-between dark-input-group">
              <div className="w-100">
                <h5 className="mb-1">{t('account-block')}</h5>
                <p className="mb-1">{t('action-instant-effect')}</p>
              </div>
              <Button size="sm" className="form-submit-button align-self-center" onClick={ async () => { await changeStatusAccount(row.login, row.status); }}>{t('block')}</Button>
            </ListGroup.Item>
            : <ListGroup.Item className="d-flex justify-content-between dark-input-group">
              <div className="w-100">
                <h5 className="mb-1">{t('account-unblock')}</h5>
                <p className="mb-1">{t('action-instant-effect')}</p>
              </div>
              <Button size="sm" className="form-submit-button align-self-center" onClick={ async () => { await changeStatusAccount(row.login, row.status); }}>{t('unblock')}</Button>
            </ListGroup.Item>
        }

        <ListGroup.Item className="d-flex justify-content-between dark-input-group">
          <div className="w-100">
            <h5 className="mb-1">{t('delete-account')}</h5>
            <p className="mb-1">{t('permanent-action')}</p>
          </div>
          {confirmDelete ? (<Button size="sm" className="form-submit-danger align-self-center" onClick={ async () => { await deleteAccount(row.login); }}>{t('confirm')}</Button>)
            : (<Button size="sm" className="form-submit-button align-self-center" onClick={ async () => { await showDeleteButton(); }}>{t('delete')}</Button>)
        }
        </ListGroup.Item>
      </ListGroup>
    ),
    onlyOneExpanding: true,
    expandByColumnOnly: true,
    expandColumnPosition: 'right',
    showExpandColumn: true,
    // eslint-disable-next-line react/display-name
    expandColumnRenderer: ({ expandable }) => (expandable
      && <Button size="sm" className="form-submit-button" onClick={onclick}>
        {t('edit')}
      </Button>),
    // eslint-disable-next-line react/display-name
    expandHeaderColumnRenderer: () => (
      <div onClick={(e) => { e.stopPropagation(); }}>{t('actions')}</div>
    ),
  };

  return (
    <>
      <Row>
        <Col>
          <div className="input-group d-flex justify-content-around">
            <div className="input-group w-50">
              <input type="text" className="form-control form-control-custom" placeholder={t('search-by-username')} onChange={(e) => { setSearchName(e.target.value); }} />
              <Button variant="warning" onClick={() => {
                addToast(t('start-typing-search'), { appearance: 'info' });
              }}>
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <BootstrapTable noDataIndication={() => t('no-data-to-display')}
            striped
            bordered={false}
            bootstrap4={true}
            classes='text-primary-gold-custom text-center'
            headerClasses='text-primary-gold-custom'
            keyField='id'
            data={filteredAccounts}
            columns={columns}
            expandRow={ expandRow }
            pagination={paginationFactory({ prePageText: 'Prev', nextPageText: 'Next', hideSizePerPage: true })}
          />
        </Col>
      </Row>
    </>
  );
};

export default AdminAccountSettings;

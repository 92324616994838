import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import toast, { ToastBar, Toaster } from 'react-hot-toast';
import { ToastProvider } from 'react-toast-notifications';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { ThemeProvider } from 'react-bootstrap';
import AuthProvider from './contexts/AuthProvider';
import MiniIcons from './components/MiniIcons';
import Header from './components/Header';
import Main from './components/Main';
import Footer from './components/Footer';
import './App.scss';

const App = () => {
  const { i18n } = useTranslation();

  return (
      <BrowserRouter>
        <GoogleReCaptchaProvider useRecaptchaNet
          reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
          scriptProps={{
            async: true, // optional, default to false,
            defer: true, // optional, default to false
            appendTo: 'body', // optional, default to "head", can be "head" or "body",
          }}>
          <ToastProvider placement="bottom-right" autoDismiss={true} autoDismissTimeout={5000}>
              <Helmet htmlAttributes={{
                lang: i18n.language,
                dir: i18n.language === 'ar-AR' ? 'rtl' : 'ltr',
              }} />
              <ThemeProvider dir={i18n.language === 'ar-AR' ? 'rtl' : 'ltr'}>
                <MiniIcons />
                  <AuthProvider>
                    <Header />
                    <Main />
                    <Footer />
                    <Toaster
                      position="bottom-right"
                      toastOptions={{
                        duration: 5000,
                        style: {
                          background: 'royalblue',
                          color: 'white',
                        },
                        success: {
                          style: {
                            background: 'green',
                            color: 'white',
                          },
                        },
                        error: {
                          style: {
                            background: 'darkred',
                            color: 'white',
                          },
                        },
                      }}
                    >
                      {(t) => (
                        <ToastBar toast={t}>
                          {({ icon, message }) => (
                            <>
                              {icon}
                              {message}
                              {t.type !== 'loading' && (
                                <button
                                  style={{ border: '0', backgroundColor: 'transparent', color: 'white' }}
                                  onClick={() => toast.dismiss(t.id)}>&#x2715;</button>
                              )}
                            </>
                          )}
                        </ToastBar>
                      )}
                    </Toaster>
                  </AuthProvider>
              </ThemeProvider>
          </ToastProvider>
        </GoogleReCaptchaProvider>
      </BrowserRouter>
  );
};

export default App;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import './MiniIcons.css';

const MiniIcons = () => {
  const { t, i18n } = useTranslation();
  const { addToast } = useToasts();

  const changeLanguage = async (newLang) => {
    await i18n.changeLanguage(newLang);
    addToast(t('language-changed'), { appearance: 'info' });
  };

  return (
    <div id="mini-icons">
      <div className="languagewrapper">
        <a className={`current-language flag-icon flag-icon-${i18n.language}`}/>
        <div className="languages ">
          {['ar-AR', 'en-US']
            .filter((lang) => lang !== i18n.language)
            .map((lang) => <a key={lang} style={{ width: '56px' }} className={`current-language flag-icon flag-icon-${lang}`} onClick={() => { changeLanguage(lang); }} />)}
        </div>
      </div>
    </div>
  );
};

export default MiniIcons;

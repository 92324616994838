import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Formik } from 'formik';
import toast from 'react-hot-toast';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {
  Button, Col, Container, Form, FormControl, InputGroup, Row,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowCircleRight, faKey, faQuestionCircle, faSignInAlt, faUser,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { post } from '../adapters/xhr';

import AuthProvider from '../contexts/AuthProvider';

const initialUser = {
  username: '',
  password: '',
};

const Login = () => {
  const { t } = useTranslation();
  const { setAccount } = useContext(AuthProvider.context);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const performLogin = async (values, token) => {
    const { username, password } = values;

    const result = await post('/api/auth/signin', {
      username,
      password,
      token,
    });

    if (result.status === 200) {
      setAccount(result.data);
    }
  };

  const initLogin = async (values) => {
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha('login');
    if (token) {
      await performLogin(values, token);
    }
  };

  const validationSchema = yup.object().shape({
    username: yup.string().required(t('field-required')),
    password: yup
      .string()
      .min(6, t('password-min'))
      .max(16, t('password-max'))
      .required(t('field-required')),
  });

  return (
    <Container>
      <Row className="pt-3 text-center">
        <Col>
          <FontAwesomeIcon icon={faSignInAlt}/>  <span className="text-uppercase">{t('login')}</span>
          <hr className="hr-color"/>
        </Col>
      </Row>
      <Row className="d-flex justify-content-around">
        <Col lg={4} className="text-center">
          <Formik
            initialValues={initialUser}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              await toast.promise(
                initLogin(values),
                {
                  loading: t('loading'),
                  success: t('login-success'),
                  error: t('login-bad-credentials'),
                },
              );

              resetForm();
              setSubmitting(false);
            }}
          >
            {({
              touched,
              errors,
              isSubmitting,
              handleSubmit,
              handleChange,
            }) => (
              <Form className="py-2 text-center" onSubmit={handleSubmit}>
                <Form.Group controlId="username">
                  <InputGroup size="sm" className={touched.username && errors.username ? 'error' : null}>
                    <InputGroup.Prepend>
                      <InputGroup.Text className="form-control-custom gray-border"><FontAwesomeIcon
                        icon={faUser}/>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      style={{ borderLeft: '0' }}
                      className='form-control-custom'
                      placeholder={t('user-name')}
                      onChange={handleChange}
                    />
                  </InputGroup>
                  {(touched.username && errors.username) && <div className="error-message">{errors.username}</div>}
                </Form.Group>
                <Form.Group controlId="password">
                  <InputGroup size="sm" className={touched.password && errors.password ? 'error' : null}>
                    <InputGroup.Prepend>
                      <InputGroup.Text className="form-control-custom gray-border"><FontAwesomeIcon
                        icon={faKey}/></InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      style={{ borderLeft: '0' }}
                      type="password"
                      className='form-control-custom gray-border'
                      placeholder={t('password')}
                      onChange={handleChange}
                    />
                  </InputGroup>
                  {(touched.password && errors.password) && <div className="error-message">{errors.password}</div>}
                </Form.Group>
                <Button type="submit" size="sm" className="form-submit-button" disabled={isSubmitting}>
                  {t('login')}
                </Button>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
      <Row className="text-center mt-3">
        <Col>
          <small><FontAwesomeIcon icon={faArrowCircleRight}/> <Link to="/register">{t('go-to-register-extended')}</Link></small>
        </Col>
      </Row>
      <Row className="text-center mt-3">
        <Col>
          <small><FontAwesomeIcon icon={faQuestionCircle}/> <Link to="/recovery/password">{t('forgot-password')}</Link></small>
        </Col>
      </Row>
      <Row className="text-center mb-3">
        <Col>
          <small><FontAwesomeIcon icon={faQuestionCircle}/> <Link to="/recovery/username">{t('forgot-username')}</Link></small>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;

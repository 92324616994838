import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTrophy } from '@fortawesome/free-solid-svg-icons';
import {
  Button, Col, ListGroup, Row,
} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ShinsoImage from '../assets/images/Shinsho_2.png';
import ChunjoImage from '../assets/images/Chunjo_2.png';
import JinnoImage from '../assets/images/Jinno_2.png';
import { deleteReq, get, patch } from '../adapters/xhr';

const AdminGuildSettings = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const [guilds, setGuilds] = useState([]);
  const [filteredGuilds, setFilteredGuilds] = useState([]);
  const [searchName, setSearchName] = useState('');

  // Expand row states
  const [newName, setNewName] = useState('');

  const handleSearch = () => {
    setFilteredGuilds(guilds
      .filter((item) => item.name.toLowerCase().includes(searchName.toLowerCase())));
  };

  const getData = async () => {
    const { data } = await get('/api/admin/guilds');

    const newData = data.map((item, index) => ({
      ...item,
      empire: (item.empire === 3 ? <img src={JinnoImage} alt="" /> : item.empire === 2 ? <img src={ChunjoImage} alt="" />
        : <img src={ShinsoImage} alt="" />),
      rank: (index === 0 ? <FontAwesomeIcon style={{ color: 'rgb(218,165,32)' }} icon={faTrophy} />
        : index === 1 ? <FontAwesomeIcon style={{ color: 'rgb(192,192,192)' }} icon={faTrophy} />
          : index === 2 ? <FontAwesomeIcon style={{ color: 'rgb(205,127,50)' }} icon={faTrophy} />
            : <span className="color-gold">{index + 1}</span>
      ),
      guild: 'Nothing yet',
      key: index,
    }));

    setGuilds(newData);
    setFilteredGuilds(newData.filter((item) => item.name.toLowerCase()
      .includes(searchName.toLowerCase())));
  };

  useEffect(async () => { await getData(); }, []);
  useEffect(() => { handleSearch(); }, [searchName]);

  const updateGuildName = async (id) => {
    try {
      await patch('/api/admin/guilds/name', {
        id,
        name: newName,
      });

      addToast(t('update-name-success'), { appearance: 'success' });
      await getData();
    } catch (e) {
      addToast(t('update-name-error'), { appearance: 'error' });
    }
  };

  const deleteGuild = async (id) => {
    try {
      await deleteReq('/api/admin/guilds', {
        data: { id },
      });

      addToast(t('delete-success'), { appearance: 'success' });
      await getData();
    } catch (error) {
      addToast(t('delete-error'), { appearance: 'error' });
    }
  };

  const columns = [
    { dataField: 'id', text: 'ID' },
    { dataField: 'rank', text: t('rank') },
    { dataField: 'name', text: t('name') },
    { dataField: 'level', text: t('level') },
    { dataField: 'empire', text: t('empire') },
    { dataField: 'master', text: t('guild-master') },
  ];

  const expandRow = {
    // eslint-disable-next-line react/display-name
    renderer: (row) => (
      <ListGroup className="text-left">
        <ListGroup.Item className="d-flex justify-content-between dark-input-group">
          <div className="w-100">
            <h5 className="mb-1">{t('change-player-name')}</h5>
            <p className="mb-1">{t('action-instant-effect')}</p>
          </div>
          <div className="input-group align-self-center w-50">
            <input type="text" className="form-control form-control-custom" placeholder={t('new-account-name')}
                   onChange={(e) => { setNewName(e.target.value); }}/>
            <Button size="sm" className="form-submit-button" onClick={ async () => { await updateGuildName(row.id); }}>{t('submit')}</Button>
          </div>
        </ListGroup.Item>

        <ListGroup.Item className="d-flex justify-content-between dark-input-group">
          <div className="w-100">
            <h5 className="mb-1">{t('player-delete')}</h5>
            <p className="mb-1">{t('permanent-action')}</p>
          </div>
          <Button size="sm" className="form-submit-button align-self-center" onClick={ async () => { await deleteGuild(row.id); }}>{t('delete')}</Button>
        </ListGroup.Item>
      </ListGroup>
    ),
    onlyOneExpanding: true,
    expandByColumnOnly: true,
    expandColumnPosition: 'right',
    showExpandColumn: true,
    // eslint-disable-next-line react/display-name
    expandColumnRenderer: ({ expandable }) => (expandable
      && <Button size="sm" className="form-submit-button" onClick={onclick}>
        {t('edit')}
      </Button>),
    // eslint-disable-next-line react/display-name
    expandHeaderColumnRenderer: () => (
      <div onClick={(e) => { e.stopPropagation(); }}>{t('actions')}</div>
    ),
  };

  return (
    <>
      <Row>
        <Col>
          <div className="input-group d-flex justify-content-around">
            <div className="input-group w-50">
              <input type="text" className="form-control form-control-custom" placeholder={t('search-by-playername')} onChange={(e) => { setSearchName(e.target.value); }} />
              <Button variant="warning" onClick={() => {
                addToast(t('start-typing-search'), { appearance: 'info' });
              }}>
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <BootstrapTable noDataIndication={() => t('no-data-to-display')}
            striped
            bordered={false}
            bootstrap4={true}
            classes='text-primary-gold-custom text-center'
            headerClasses='text-primary-gold-custom'
            keyField='id'
            data={filteredGuilds}
            columns={columns}
            expandRow={ expandRow }
            pagination={paginationFactory({ prePageText: 'Prev', nextPageText: 'Next', hideSizePerPage: true })}
          />
        </Col>
      </Row>
    </>
  );
};

export default AdminGuildSettings;

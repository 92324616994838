import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import {
  Container, Row, Col, Form, Alert,
} from 'react-bootstrap';
import { get, patch } from '../adapters/xhr';

const AdminGlobalSettings = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const [globalSettings, setGlobalSettings] = useState({
    registerStatus: false,
    registerEmailConfirmStatus: false,
  });

  const getGlobalSettings = async () => {
    const { data } = await get('/api/admin/global');
    setGlobalSettings(data);
  };

  useEffect(async () => { await getGlobalSettings(); }, []);

  const changeRegisterStatus = async (status) => {
    try {
      await patch('/api/admin/global/register', {
        status,
      });

      addToast(t('update-global-settings-success'), { appearance: 'success' });
      await getGlobalSettings();
    } catch (e) {
      addToast(t('update-global-settings-error'), { appearance: 'error' });
    }
  };

  const changeRegisterConfirmStatus = async (status) => {
    try {
      await patch('/api/admin/global/registerEmail', {
        status,
      });

      addToast(t('update-global-settings-success'), { appearance: 'success' });
      await getGlobalSettings();
    } catch (e) {
      addToast(t('update-global-settings-error'), { appearance: 'error' });
    }
  };

  return (
    <Container>
      <Row>
        <Col>
          <Alert variant="warning">
            These settings are very important and dangerous to change! Be cautious!
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form>
            <Form.Check
              type="switch"
              id="register"
              label={t('register-status')}
              checked={globalSettings.registerStatus}
              onChange={async (e) => { await changeRegisterStatus(e.target.checked); }}
            />
            <Form.Check
              type="switch"
              id="registerConfirmEmail"
              label={t('register-confirm-status')}
              checked={globalSettings.registerEmailConfirmStatus}
              onChange={async (e) => { await changeRegisterConfirmStatus(e.target.checked); }}
            />
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminGlobalSettings;

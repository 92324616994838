import React from 'react';
import { Link } from 'react-router-dom';
import {
  Container, Row, Col, Image,
} from 'react-bootstrap';

import Logo from '../assets/images/logo.png';
import './Footer.css';

const Footer = () => (
  <footer className="text-muted">
    <Container fluid>
      <Row>
        <Col className="text-center">
          <Image src={Logo} className="footer-logo" alt="Logo" />
        </Col>
      </Row>
      <Row className="mt-3 py-4 footer-links-bg">
        <Col lg={6} className="text-primary-custom text-center">
          <div>All rights reserved by Metin2Community.com</div>
        </Col>
        <Col lg={6} className="text-secondary-custom text-center">
          <div>
            <Link to="/">Homepage</Link>&nbsp;|&nbsp;
            <Link to="/download">Download</Link>&nbsp;|&nbsp;
            <Link to="/register">Register</Link>&nbsp;|&nbsp;
            <Link to="/ranking">Ranking</Link>&nbsp;|&nbsp;
            <a href={process.env.REACT_APP_FORUM_URL} target="_blank" rel="noreferrer">Forum</a>&nbsp;|&nbsp;
            <a href={process.env.REACT_APP_DISCORD_URL} target="_blank" rel="noreferrer">Discord</a>&nbsp;
          </div>
        </Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import {
  Button, Col, Container, Form, FormControl, InputGroup, Row, Alert,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { patch, post } from '../adapters/xhr';

const ResetPasswordConfirm = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const { username, uniqueKey } = useParams();

  const [validationStatus, setValidationStatus] = useState(0);
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');

  const performPasswordChange = async () => {
    if (newPassword === newPasswordConfirm) {
      try {
        await patch('/api/user/account/recovery/password', {
          username,
          uniqueKey,
          newPassword,
        });

        addToast(t('reset-password-success'), { appearance: 'success' });
      } catch (e) {
        addToast(t('reset-password-error'), { appearance: 'error' });
      }
    } else {
      addToast(t('passwords-not-match'), { appearance: 'error' });
    }
  };

  // Check if unique key is valid for the account reset process
  useEffect(async () => {
    try {
      await post('/api/user/account/recovery/password/validate', {
        username,
        uniqueKey,
      });

      setValidationStatus(1);
    } catch (e) {
      setValidationStatus(-1);
    }
  }, []);

  switch (validationStatus) {
    case 1: return (
      <Container>
        <Row className="pt-3 text-center">
          <Col>
            <h5 className="text-uppercase"><FontAwesomeIcon icon={faUserPlus}/> {t('reset-password')}</h5>
          </Col>
        </Row>
        <hr className="hr-color"/>
        <Row>
          <Col>
            <Alert variant="success">
              The provided username and secret key combination is correct.
              Now please enter carefully a new password and you&lsquo;re good to go!
            </Alert>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center">
            <Form className="py-2 text-center">
              <InputGroup size="sm" className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text style={{ color: 'white' }}
                                   className="form-control-custom">{t('new-password')}</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  className="form-control-custom"
                  type="password"
                  onChange={(e) => { setNewPassword(e.target.value); }}
                />
              </InputGroup>

              <InputGroup size="sm" className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon2"
                                   className="form-control-custom">{t('new-password-verify')}</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  className="form-control-custom"
                  type="password"
                  onChange={(e) => { setNewPasswordConfirm(e.target.value); }}
                />
              </InputGroup>

              <Button size="sm" className="form-submit-button" onClick={async () => {
                await performPasswordChange();
              }}>
                {t('submit')}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    );
    case -1: return (
      <Container>
        <Row className="pt-3 text-center">
          <Col>
            <h5 className="text-uppercase"><FontAwesomeIcon icon={faUserPlus}/> {t('reset-password')}</h5>
          </Col>
        </Row>
        <hr className="hr-color"/>
        <Row>
          <Col>
            <Alert variant="danger">
              <Alert.Heading>Aw, snap :(</Alert.Heading>
              <p>
                The username and secret key you provided is not correct
                or it might have already expired, having a time limit of 24h.
              </p>
              <p>
                If that&lsquo;s the case, please try to
                reset your password again, and it should work next time!
              </p>
              <hr />
              <p className="mb-0">
                If you think this error is wrong, please contact a staff member as soon as possible.
              </p>
            </Alert>
          </Col>
        </Row>
      </Container>
    );
    default: return <div/>;
  }
};

export default ResetPasswordConfirm;

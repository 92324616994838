import axios from 'axios';
import { AXIOS_ALLOW_CREDENTIALS } from '../../util/constants';

const returnAxiosInstance = () => axios.create({
  baseURL: `${process.env.REACT_APP_API_SERVER_URL}`,
  withCredentials: AXIOS_ALLOW_CREDENTIALS,
});

export const get = (endpoint, reqData) => {
  const instance = returnAxiosInstance();
  return instance.get(endpoint, reqData);
};

const getCsrfToken = async () => {
  const { data } = await get('/api/csrf-token');
  const { csrfToken } = data;
  return csrfToken;
};

export const post = async (endpoint, reqData) => {
  const instance = returnAxiosInstance();
  instance.defaults.headers.post['X-CSRF-Token'] = await getCsrfToken();

  return instance.post(endpoint, reqData);
};

export const patch = async (endpoint, reqData) => {
  const instance = returnAxiosInstance();
  instance.defaults.headers.patch['X-CSRF-Token'] = await getCsrfToken();

  return instance.patch(endpoint, reqData);
};

export const put = async (endpoint, reqData) => {
  const instance = returnAxiosInstance();
  instance.defaults.headers.patch['X-CSRF-Token'] = await getCsrfToken();

  return instance.put(endpoint, reqData);
};

export const deleteReq = async (endpoint, reqData) => {
  const instance = returnAxiosInstance();
  instance.defaults.headers.delete['X-CSRF-Token'] = await getCsrfToken();

  return instance.delete(endpoint, reqData);
};

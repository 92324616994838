import React from 'react';
import { Carousel } from 'react-bootstrap';

import './Carousel.css';
import carousel1 from '../assets/images/carousel1.png';
import carousel2 from '../assets/images/carousel2.jpg';
import carousel3 from '../assets/images/carousel3.jpg';

const Slider = () => (
  <Carousel fade className="carousel-panel fill">
    <Carousel.Item>
      <img
        className="d-block w-100"
        src={carousel1}
        alt="First slide"
      />
    </Carousel.Item>
    <Carousel.Item>
      <img
        className="d-block w-100"
        src={carousel2}
        alt="Second slide"
      />
    </Carousel.Item>
    <Carousel.Item>
      <img
        className="d-block w-100"
        src={carousel3}
        alt="Second slide"
      />
    </Carousel.Item>
  </Carousel>
);

export default Slider;

import React from 'react';

const NotFound = () => (
  <div className="text-center my-5">
    <p>
      <h1>Oops!</h1>
    </p>
    <p>
      The requested URL could not be found.
    </p>
  </div>
);

export default NotFound;

import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { Container, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import NewsDetailed from './NewsDetailed';
import './News.css';
import { get } from '../adapters/xhr';

const News = () => {
  const { t } = useTranslation();

  const [news, setNews] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [selectedPage, setSelectedPage] = useState(0);

  const getTotalPageCount = async () => {
    const result = await get('/api/news/count');
    setPageCount(Math.ceil(result.data.total_news / 3));
  };

  const getNews = async () => {
    const result = await get('/api/news/offset', {
      params: {
        offset: selectedPage * 3,
      },
    });
    const resultFinal = result.data.map((item) => ({
      ...item,
      date: new Date(item.date).toUTCString(),
      image: item.image ? item.image : 'default_news.gif',
    }));
    setNews(resultFinal);
  };

  useEffect(async () => { await getTotalPageCount(); }, []);
  useEffect(async () => { await getNews(); }, [selectedPage]);

  const handlePageClick = (data) => {
    setSelectedPage(data.selected);
  };

  return (
    <Container>
      <Row className="pt-3 text-center">
        <Col>
          <h5><FontAwesomeIcon icon={faBullhorn}/> {t('news')}</h5>
        </Col>
      </Row>
      <hr className="hr-color"/>
      <Row>
        <Col>
          {news.map((item) => <NewsDetailed data={item} key={item.id} />)}
        </Col>
      </Row>
      <Row id="react-paginate">
        <ReactPaginate
          previousLabel={'<'}
          nextLabel={'>'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={(data) => { handlePageClick(data); }}
          containerClassName={'pagination'}
          activeClassName={'page-active'}
          pageClassName={'page'}
        />
      </Row>
    </Container>
  );
};

export default News;

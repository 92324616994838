import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import {
  Row, Col, InputGroup, FormControl, Form, Button, Alert,
} from 'react-bootstrap';
import { post } from '../adapters/xhr';

const UserChangePassword = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const [password, setPassword] = useState('');
  const [email, setNewEmail] = useState('');
  const [emailVerify, setNewEmailVerify] = useState('');

  const performChangeEmail = async () => {
    if (email === emailVerify) {
      try {
        await post('/api/user/account/email/begin', {
          password,
          newEmail: email,
        });

        addToast(t('change-process-success'), { appearance: 'success' });
      } catch (e) {
        if (e.response.status === 422) {
          addToast(t('emails-same'), { appearance: 'warning' });
        } else if (e.response.status === 403) {
          addToast(t('password-wrong'), { appearance: 'error' });
        } else if (e.response.status === 400) {
          addToast(t('change-process-delay'), { appearance: 'warning' });
        } else {
          addToast(t('process-error'), { appearance: 'error' });
        }
      }
    } else {
      addToast(t('emails-not-match'), { appearance: 'error' });
    }
  };

  return (
    <>
    <Row className="mt-4">
      <Col>
        <Alert variant="info">
          <Alert.Heading>{t('reminder')}</Alert.Heading>
          <p>
            {t('email-will-arrive')}
          </p>
          <hr />
          <p className="mb-0">
            {t('thanks-for-understanding')}
          </p>
          <blockquote className="blockquote">
            <footer className="blockquote-footer text-right">{t('king-regards')} <cite title="Source Title">{t('your-team')}</cite>
            </footer>
          </blockquote>
        </Alert>
      </Col>
    </Row>
    <Row className="mt-4">
      <Col className="d-flex justify-content-center">
        <Form className="py-2 text-center">
          <InputGroup size="sm" className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text style={{ color: 'white' }}
                               className="form-control-custom">{t('password')}</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              type="password"
              className="form-control-custom"
              onChange={(e) => { setPassword(e.target.value); }}
            />
          </InputGroup>

          <InputGroup size="sm" className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon2"
                               className="form-control-custom">{t('new-email')}</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              type="email"
              className="form-control-custom"
              onChange={(e) => { setNewEmail(e.target.value); }}
            />
          </InputGroup>

          <InputGroup size="sm" className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon2"
                               className="form-control-custom">{t('new-email-verify')}</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              type="email"
              className="form-control-custom"
              onChange={(e) => { setNewEmailVerify(e.target.value); }}
            />
          </InputGroup>

          <Button size="sm" className="form-submit-button" onClick={async () => {
            await performChangeEmail();
          }}>
            {t('change-email')}
          </Button>
        </Form>
      </Col>
    </Row>
      </>
  );
};

export default UserChangePassword;

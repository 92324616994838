import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col, Container, Nav, Row,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

import UserProfileInfo from './UserProfileInfo';
import UserProfileSettings from './UserProfileSettings';
import UserProfileCharacters from './UserProfileCharacters';

const UserProfile = () => {
  const { t } = useTranslation();

  const [selectedNav, setSelectedNav] = useState('profile');

  const renderSelectedNav = () => {
    switch (selectedNav) {
      case 'characters': return <UserProfileCharacters />;
      case 'settings': return <UserProfileSettings />;
      default: return <UserProfileInfo />;
    }
  };

  return (
    <Container className="py-3">
      <Row>
        <Col className="text-center">
          <h5 className="text-uppercase"><FontAwesomeIcon icon={faUser}/> {t('user-panel')}</h5>
        </Col>
      </Row>
      <hr className="hr-color" />
      <Row className="mb-3">
        <Col>
          <Nav fill defaultActiveKey="profile">
            <Nav.Item className="choose-settings-navbar-item">
              <Nav.Link eventKey="profile" onClick={() => { setSelectedNav('profile'); }}>{t('user-profile')}</Nav.Link>
            </Nav.Item>
            <Nav.Item className="choose-settings-navbar-item">
              <Nav.Link eventKey="characters" onClick={() => { setSelectedNav('characters'); }}>{t('characters')}</Nav.Link>
            </Nav.Item>
            <Nav.Item className="choose-settings-navbar-item">
              <Nav.Link eventKey="settings" onClick={() => { setSelectedNav('settings'); }}>{t('user-settings')}</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
      </Row>
      { renderSelectedNav() }
    </Container>
  );
};

export default UserProfile;

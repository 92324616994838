import React, { useEffect, useState } from 'react';
import {
  Container, Row, Col, Image, Alert,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useTranslation } from 'react-i18next';

import MegaImage from '../assets/images/download/download-mega.png';
import TorrentImage from '../assets/images/download/download-torrent.png';
import MirrorImage from '../assets/images/download/download-mirror.png';
import GoogleImage from '../assets/images/download/download-drive.png';
import { get } from '../adapters/xhr';

const Download = () => {
  const { t } = useTranslation();

  const [downloads, setDownloads] = useState([]);

  const getDownloads = async () => {
    const { data } = await get('/api/downloads');

    const newData = data.map((item) => ({
      ...item,
      link: <a target="_blank" rel="noreferrer" className="text-uppercase text-decoration-none text-white" href={item.link}>{t('download')}</a>,
      type: <Image className="download-img" src={(
          item.type === 'mega' ? MegaImage
            : item.type === 'torrent' ? TorrentImage
              : item.type === 'google' ? GoogleImage
                : MirrorImage)
      } alt="" />,
    }));

    setDownloads(newData);
  };

  useEffect(async () => { await getDownloads(); }, []);

  const columns = [
    { dataField: 'name', text: t('name') },
    { dataField: 'type', text: t('type') },
    { dataField: 'link', text: 'Link' },
  ];

  return (
    <Container>
      <Row className="pt-3 text-center">
        <Col>
          <h5 className="text-uppercase"><FontAwesomeIcon icon={faCloudDownloadAlt}/> {t('download')}</h5>
        </Col>
      </Row>
      <hr className="hr-color"/>
      <Row className="mb-3">
        <Col>
          <Alert variant="info">
            <Alert.Heading>{t('hey_welcome')}</Alert.Heading>
            <p>
              {t('download_alert')}
            </p>
            <hr />
            <p className="mb-0">
              {t('have_great_time')}
            </p>
            <blockquote className="blockquote">
              <footer className="blockquote-footer text-right">{t('king-regards')} <cite title="Source Title">{t('your-team')}</cite>
              </footer>
            </blockquote>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <BootstrapTable noDataIndication={() => t('no-data-to-display')}
            striped
            bordered={false}
            bootstrap4={true}
            classes='text-primary-gold-custom text-center'
            headerClasses='text-primary-gold-custom'
            keyField='id'
            data={downloads}
            columns={columns}
            // expandRow={ expandRow }
            pagination={paginationFactory({ prePageText: 'Prev', nextPageText: 'Next', hideSizePerPage: true })}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Download;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { DateTime } from 'luxon';
import {
  Button, Col, Form, ListGroup, Row,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { deleteReq, get, put } from '../adapters/xhr';

const AdminAccountBanlist = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const [accounts, setAccounts] = useState([]);
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [searchName, setSearchName] = useState('');

  const [blockedUsername, setBlockedUsername] = useState('');
  const [reason, setReason] = useState('');
  const [proof, setProof] = useState('');
  const [showForm, setShowForm] = useState(false);

  const handleSearch = () => {
    setFilteredAccounts(accounts
      .filter((item) => item.blocked_username.toLowerCase().includes(searchName.toLowerCase())));
  };

  const getAccounts = async () => {
    const { data } = await get('/api/admin/blocked_accounts');
    const newData = data.map((item) => ({
      ...item,
      request_time: DateTime.fromISO(item.request_time).toFormat('fff'),
      proof: <a href={item.proof} target="_blank" rel="noreferrer">click</a>,
    }));

    setAccounts(newData);
    setFilteredAccounts(newData.filter((item) => item.blocked_username.toLowerCase()
      .includes(searchName.toLowerCase())));
  };

  useEffect(async () => { await getAccounts(); }, []);
  useEffect(() => { handleSearch(); }, [searchName]);

  const blockAccount = async () => {
    try {
      await put('/api/admin/blocked_accounts', {
        blockedUsername,
        reason,
        proof,
      });

      addToast(t('account-status-success'), { appearance: 'success' });
      await getAccounts();
    } catch (error) {
      addToast(t('account-status-error'), { appearance: 'error' });
    }
  };

  const revertAccountBlock = async (username) => {
    try {
      await deleteReq('/api/admin/blocked_accounts', {
        data: {
          username,
        },
      });

      addToast(t('account-status-success'), { appearance: 'success' });
      await getAccounts();
    } catch (error) {
      addToast(t('account-status-error'), { appearance: 'error' });
    }
  };

  const expandRow = {
    // eslint-disable-next-line react/display-name
    renderer: (row) => (
      <ListGroup className="text-left">
        <ListGroup.Item className="d-flex justify-content-between dark-input-group">
          <div className="w-100">
            <h5 className="mb-1">{t('account-unblock')}</h5>
            <p className="mb-1">{t('permanent-action')}</p>
          </div>
          <Button size="sm" className="form-submit-button align-self-center" onClick={ async () => { await revertAccountBlock(row.blocked_username); }}>{t('submit')}</Button>
        </ListGroup.Item>
      </ListGroup>
    ),
    onlyOneExpanding: true,
    expandByColumnOnly: true,
    expandColumnPosition: 'right',
    showExpandColumn: true,
    // eslint-disable-next-line react/display-name
    expandColumnRenderer: ({ expandable }) => (expandable
      && <Button size="sm" className="form-submit-button" onClick={onclick}>
        {t('edit')}
      </Button>),
    // eslint-disable-next-line react/display-name
    expandHeaderColumnRenderer: () => (
      <div onClick={(e) => { e.stopPropagation(); }}>{t('actions')}</div>
    ),
  };

  const columns = [
    { dataField: 'id', text: 'ID' },
    { dataField: 'staff_username', text: t('staff-username') },
    { dataField: 'blocked_username', text: t('blocked-username') },
    { dataField: 'reason', text: t('reason') },
    { dataField: 'proof', text: t('proof') },
    { dataField: 'request_time', text: t('request-time') },
  ];

  return (
    <>
      <Row className="mb-3">
        <Col className="form-bordered-gold text-center">
          {showForm ? <Form>
            <Form.Group as={Row}>
              <Form.Label column sm={6}>
                {t('blocked-username')}
              </Form.Label>
              <Col sm={6}>
                <Form.Control className="form-control-custom" size="sm" onChange={(e) => { setBlockedUsername(e.target.value); }} />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={6}>
                {t('proof')}
              </Form.Label>
              <Col sm={6}>
                <Form.Control className="form-control-custom" size="sm"
                              onChange={(e) => { setProof(e.target.value); }} />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={6}>
                {t('reason')}
              </Form.Label>
              <Col sm={6}>
                <Form.Control className="form-control-custom" size="sm" as="textarea" rows={10}
                              onChange={(e) => { setReason(e.target.value); }} />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="text-center">
              <Col sm={12}>
                <Button size="sm" className="form-submit-button" onClick={ async () => { await blockAccount(); setShowForm(false); }}>
                  {t('add-block')}
                </Button>
              </Col>
            </Form.Group>
          </Form> : <Button size="sm" className="form-submit-button" onClick={() => { setShowForm(true); }}>
            {t('add-block')}
          </Button>}
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="input-group d-flex justify-content-around">
            <div className="input-group w-50">
              <input type="text" className="form-control form-control-custom" placeholder={t('search-by-username')} onChange={(e) => { setSearchName(e.target.value); }} />
              <Button variant="warning" onClick={() => {
                addToast(t('start-typing-search'), { appearance: 'info' });
              }}>
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <BootstrapTable noDataIndication={() => t('no-data-to-display')}
            striped
            bordered={false}
            bootstrap4={true}
            classes='text-primary-gold-custom text-center'
            headerClasses='text-primary-gold-custom'
            keyField='id'
            data={filteredAccounts}
            columns={columns}
            expandRow={ expandRow }
            pagination={paginationFactory({ prePageText: 'Prev', nextPageText: 'Next', hideSizePerPage: true })}
          />
        </Col>
      </Row>
    </>
  );
};

export default AdminAccountBanlist;

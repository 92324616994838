import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Link } from 'react-router-dom';
import {
  Container, Row, Col, Form, InputGroup, FormControl, Button, ListGroup, Image,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as yup from 'yup';
import { Formik } from 'formik';
import toast from 'react-hot-toast';
import {
  faKey, faUser, faShoppingCart, faSignOutAlt, faUserShield,
} from '@fortawesome/free-solid-svg-icons';

import AuthProvider from '../contexts/AuthProvider';
import defaultUserPanelImage from '../assets/images/default-panel.png';
import './UserPanel.css';
import { post } from '../adapters/xhr';

const initialUser = {
  username: '',
  password: '',
};

const UserPanel = () => {
  const { t } = useTranslation();
  const { account, setAccount } = useContext(AuthProvider.context);
  const { auth, user } = account;

  const { executeRecaptcha } = useGoogleReCaptcha();

  const performLogin = async (values, token) => {
    const { username, password } = values;

    const result = await post('/api/auth/signin', {
      username,
      password,
      token,
    });

    if (result.status === 200) {
      setAccount(result.data);
    }
  };

  const initLogin = async (values) => {
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha('login');
    if (token) {
      await performLogin(values, token);
    }
  };

  const validationSchema = yup.object().shape({
    username: yup.string().required(t('field-required')),
    password: yup
      .string()
      .min(6, t('password-min'))
      .max(16, t('password-max'))
      .required(t('field-required')),
  });

  const performLogout = async () => {
    const { data } = await post('/api/auth/signout');
    setAccount(data);
    toast.success(t('logout-success'));
  };

  if (auth) {
    return (
      <Container className="user-panel">
        <Row className="pt-1 text-center">
          <Col>
            <div>
              <Image width="70px" src={defaultUserPanelImage} alt="profile avatar" />
            </div>
            <small>{t('welcome')} {user.username}</small>
          </Col>
        </Row>
        <hr className="hr-color" />
        <Row className="text-center">
          <Col>
            <ListGroup className="user-panel-list text-left">
              {user.admin > 0 && <ListGroup.Item as={Link} to="/admin/profile" className="user-panel-item"><FontAwesomeIcon icon={faUserShield} /> {t('admin-panel')}</ListGroup.Item>}
              <ListGroup.Item as={Link} to="/user/profile" className="user-panel-item"><FontAwesomeIcon icon={faUser} /> {t('user-panel')}</ListGroup.Item>
              <ListGroup.Item className="user-panel-item"><a href={process.env.REACT_APP_ITEM_SHOP_URL} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faShoppingCart} /> {t('item-shop')}</a></ListGroup.Item>
              <ListGroup.Item onClick={async () => { await performLogout(); }} className="user-panel-item-logout"><FontAwesomeIcon icon={faSignOutAlt} /> {t('logout')}</ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container className="user-panel">
      <Row className="pt-3 text-center">
        <Col>
          <span className="text-capitalize">{t('user-panel')}</span>
          <hr className="hr-color"/>
        </Col>
      </Row>
      <Row>
        <Col>
          <Formik
            initialValues={initialUser}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              await toast.promise(
                initLogin(values),
                {
                  loading: t('loading'),
                  success: t('login-success'),
                  error: t('login-bad-credentials'),
                },
              );

              resetForm();
              setSubmitting(false);
            }}
          >
            {({
              touched,
              errors,
              isSubmitting,
              handleSubmit,
              handleChange,
            }) => (
              <Form className="py-2 text-center" onSubmit={handleSubmit}>
                <Form.Group controlId="username">
                  <InputGroup size="sm" className={touched.username && errors.username ? 'error' : null}>
                    <InputGroup.Prepend>
                      <InputGroup.Text className="form-control-custom gray-border"><FontAwesomeIcon
                        icon={faUser}/>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      style={{ borderLeft: '0' }}
                      className='form-control-custom'
                      placeholder={t('user-name')}
                      onChange={handleChange}
                    />
                  </InputGroup>
                  {(touched.username && errors.username) && <div className="error-message">{errors.username}</div>}
                </Form.Group>
                <Form.Group controlId="password">
                  <InputGroup size="sm" className={touched.password && errors.password ? 'error' : null}>
                    <InputGroup.Prepend>
                      <InputGroup.Text className="form-control-custom gray-border"><FontAwesomeIcon
                        icon={faKey}/></InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      style={{ borderLeft: '0' }}
                      type="password"
                      className='form-control-custom gray-border'
                      placeholder={t('password')}
                      onChange={handleChange}
                    />
                  </InputGroup>
                  {(touched.password && errors.password) && <div className="error-message">{errors.password}</div>}
                </Form.Group>
                <Button type="submit" size="sm" className="form-submit-button" disabled={isSubmitting}>
                  {t('login')}
                </Button>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
      <Row>
        <Col>
          <small className="text-secondary-custom"><Link to="/recovery/password">{t('forgot-password')}</Link></small>
        </Col>
      </Row>
      <Row>
        <Col>
          <small className="text-secondary-custom"><Link to="/recovery/username">{t('forgot-username')}</Link></small>
        </Col>
      </Row>
    </Container>
  );
};

export default UserPanel;

import React from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import './NewsDetailed.css';

const NewsDetailed = (props) => {
  const { data } = props;

  const { t } = useTranslation();

  return (
    <article className="pt-3 pb-4">
      <div className="d-flex justify-content-between">
        <span className="h5" style={{ color: '#d7a359' }}>
          {data.title}
        </span>
        <span>
          <small style={{ color: '#d7a359' }}>{data.date}</small>
        </span>
      </div>
      <div className="text-center">
        <img className="announcement-image" src={`${process.env.REACT_APP_API_SERVER_URL}/assets/uploads/${data.image}`} alt='News gif image representing the content'/>
      </div>
      <div className="mt-4 mx-4">
        <blockquote className="blockquote">
          <p className="mb-0"><small>{data.content.length > 250 ? `${parse(data.content.substring(0, 250))}...` : parse(data.content)}</small></p>
          <footer className="blockquote-footer text-right">{t('king-regards')} <cite title="Source Title">{t('your-team')}</cite>
          </footer>
        </blockquote>
      </div>
    </article>
  );
};

export default NewsDetailed;

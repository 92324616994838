import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Nav,
} from 'react-bootstrap';

import UserChangePassword from './UserChangePassword';
import UserChangeEmail from './UserChangeEmail';
import UserChangeSafeboxPassword from './UserChangeSafeboxPassword';
import UserChangeCharDelPassword from './UserChangeCharDelPassword';
import UserResetSafeboxPassword from './UserResetSafeboxPassword';
import UserResetSocialid from './UserResetSocialid';

const UserProfileSettings = () => {
  const { t } = useTranslation();

  const [selectedSettingsPage, setSelectedSettingsPage] = useState(null);

  const renderSelectedSettingsPage = () => {
    switch (selectedSettingsPage) {
      case 'web-password-change': return <UserChangePassword />;
      case 'web-email-change': return <UserChangeEmail />;
      case 'web-safebox-change': return <UserChangeSafeboxPassword />;
      case 'web-socialid-change': return <UserChangeCharDelPassword />;
      case 'web-safebox-reset': return <UserResetSafeboxPassword />;
      case 'web-socialid-reset': return <UserResetSocialid />;
      default: return <div />;
    }
  };

  return (
    <>
      <Nav className="d-flex justify-content-around">
        <Nav.Item className="choose-settings-navbar-item">
          <Nav.Link eventKey="web-password-change" onClick={() => { setSelectedSettingsPage('web-password-change'); }}>{t('change-password')}</Nav.Link>
        </Nav.Item>
        <Nav.Item className="choose-settings-navbar-item">
          <Nav.Link eventKey="web-email-change" onClick={() => { setSelectedSettingsPage('web-email-change'); }}>{t('change-email')}</Nav.Link>
        </Nav.Item>
        <Nav.Item className="choose-settings-navbar-item">
          <Nav.Link eventKey="web-safebox-change" onClick={() => { setSelectedSettingsPage('web-safebox-change'); }}>{t('change-safebox-password')}</Nav.Link>
        </Nav.Item>
        <Nav.Item className="choose-settings-navbar-item">
          <Nav.Link eventKey="web-socialid-change" onClick={() => { setSelectedSettingsPage('web-socialid-change'); }}>{t('change-character-del-password')}</Nav.Link>
        </Nav.Item>
        <Nav.Item className="choose-settings-navbar-item">
          <Nav.Link eventKey="web-safebox-reset" onClick={() => { setSelectedSettingsPage('web-safebox-reset'); }}>{t('reset-safebox-password')}</Nav.Link>
        </Nav.Item>
        <Nav.Item className="choose-settings-navbar-item">
          <Nav.Link eventKey="web-socialid-reset" onClick={() => { setSelectedSettingsPage('web-socialid-reset'); }}>{t('reset-character-del-password')}</Nav.Link>
        </Nav.Item>
      </Nav>
      { renderSelectedSettingsPage() }
    </>
  );
};

export default UserProfileSettings;

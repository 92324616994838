import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Col, Container, Row,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';
import RankingPlayers from './RankingPlayers';
import RankingGuilds from './RankingGuilds';

const Ranking = () => {
  const { t } = useTranslation();

  const [selectedPage, setSelectedPage] = useState(0);

  const renderSelectedPage = () => {
    switch (selectedPage) {
      case 0: return <RankingPlayers />;
      case 1: return <RankingGuilds />;
      default: return <div />;
    }
  };

  return (
    <Container>
      <Row className="pt-3 text-center">
        <Col>
          <h5><FontAwesomeIcon icon={faTrophy}/> <span className="text-capitalize">{t('ranking')}</span></h5>
        </Col>
      </Row>
      <hr className="hr-color"/>
      <Row className="mb-3">
        <Col className="text-center">
          <Button size="md" className="form-submit-button" onClick={() => { setSelectedPage(0); }}>
            {t('players')}
          </Button>
        </Col>
        <Col className="text-center">
          <Button size="md" className="form-submit-button" onClick={() => { setSelectedPage(1); }}>
            {t('guilds')}
          </Button>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          { renderSelectedPage() }
        </Col>
      </Row>
    </Container>
  );
};

export default Ranking;

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import './i18n';

import App from './App';
// eslint-disable-next-line import/no-named-as-default,import/no-named-as-default-member
import reportWebVitals from './reportWebVitals';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Loading from './components/Loading';

axios.defaults.withCredentials = process.env.REACT_APP_AXIOS_ALLOW_CREDENTIALS;

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Loading />}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import {
  Row, Col, InputGroup, FormControl, Form, Button,
} from 'react-bootstrap';
import { patch } from '../adapters/xhr';

const UserChangePassword = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordVerify, setNewPasswordVerify] = useState('');

  const performChangePassword = async () => {
    if (oldPassword === newPassword) {
      addToast(t('passwords-same'), { appearance: 'warning' });
    } else if (newPassword === newPasswordVerify) {
      try {
        await patch('/api/user/account/password', {
          oldPassword,
          newPassword,
        });

        addToast(t('change-password-success'), { appearance: 'success' });
      } catch (e) {
        if (e.response.status === 422) {
          addToast(t('passwords-same'), { appearance: 'warning' });
        } else if (e.response.status === 403) {
          addToast(t('old-password-wrong'), { appearance: 'error' });
        } else {
          addToast(t('change-password-error'), { appearance: 'error' });
        }
      }
    } else {
      addToast(t('passwords-not-match'), { appearance: 'error' });
    }
  };

  return (
    <Row className="mt-4">
      <Col className="d-flex justify-content-center">
        <Form className="py-2 text-center">
          <InputGroup size="sm" className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text style={{ color: 'white' }}
                               className="form-control-custom">{t('old-password')}</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              type="password"
              className="form-control-custom"
              onChange={(e) => { setOldPassword(e.target.value); }}
            />
          </InputGroup>

          <InputGroup size="sm" className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon2"
                               className="form-control-custom">{t('new-password')}</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              type="password"
              className="form-control-custom"
              onChange={(e) => { setNewPassword(e.target.value); }}
            />
          </InputGroup>

          <InputGroup size="sm" className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon2"
                               className="form-control-custom">{t('new-password-verify')}</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              type="password"
              className="form-control-custom"
              onChange={(e) => { setNewPasswordVerify(e.target.value); }}
            />
          </InputGroup>

          <Button size="sm" className="form-submit-button" onClick={async () => {
            await performChangePassword();
          }}>
            {t('change-password')}
          </Button>
        </Form>
      </Col>
    </Row>
  );
};

export default UserChangePassword;

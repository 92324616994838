import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { faSearch, faTrophy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Row } from 'react-bootstrap';
import JinnoImage from '../assets/images/Jinno_2.png';
import ChunjoImage from '../assets/images/Chunjo_2.png';
import ShinsoImage from '../assets/images/Shinsho_2.png';
import { get } from '../adapters/xhr';

const RankingPlayers = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const [players, setPlayers] = useState([]);
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [searchName, setSearchName] = useState('');

  const handleSearch = () => {
    setFilteredPlayers(players
      .filter((item) => item.name.toLowerCase().includes(searchName.toLowerCase())));
  };

  const getData = async () => {
    const { data } = await get('/assets/cache/players.json');

    const newData = data.map((item, index) => ({
      ...item,
      empire: (item.empire === 3 ? <img src={JinnoImage} alt="" /> : item.empire === 2 ? <img src={ChunjoImage} alt="" />
        : <img src={ShinsoImage} alt="" />),
      rank: (index === 0 ? <FontAwesomeIcon style={{ color: 'rgb(218,165,32)' }} icon={faTrophy} />
        : index === 1 ? <FontAwesomeIcon style={{ color: 'rgb(192,192,192)' }} icon={faTrophy} />
          : index === 2 ? <FontAwesomeIcon style={{ color: 'rgb(205,127,50)' }} icon={faTrophy} />
            : <span>{index + 1}.</span>
      ),
      key: index,
    }));

    setPlayers(newData);
    setFilteredPlayers(newData.filter((item) => item.name.toLowerCase()
      .includes(searchName.toLowerCase())));
  };

  useEffect(async () => { await getData(); }, []);
  useEffect(() => { handleSearch(); }, [searchName]);

  const columns = [
    { dataField: 'rank', text: t('rank') },
    { dataField: 'name', text: t('name') },
    { dataField: 'empire', text: t('empire') },
    { dataField: 'level', text: t('level') },
    { dataField: 'guild', text: t('guild') },
  ];

  return (
    <>
      <Row>
        <Col>
          <div className="input-group d-flex justify-content-around">
            <div className="input-group w-50">
              <input type="text" className="form-control form-control-custom" placeholder={t('search-by-playername')} onChange={(e) => { setSearchName(e.target.value); }} />
              <Button variant="warning" onClick={() => {
                addToast(t('start-typing-search'), { appearance: 'info' });
              }}>
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <BootstrapTable noDataIndication={() => t('no-data-to-display')}
            striped
            bordered={false}
            bootstrap4={true}
            classes='text-primary-gold-custom text-center'
            headerClasses='text-primary-gold-custom'
            keyField='key'
            data={filteredPlayers}
            columns={columns}
            pagination={paginationFactory({ prePageText: t('previous'), nextPageText: t('next'), hideSizePerPage: true })}
          />
        </Col>
      </Row>
    </>
  );
};

export default RankingPlayers;
